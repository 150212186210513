import React, { useEffect, useState } from 'react';

function ButtonWithCount({ delay, onClick, label, sessionKey }) {
  const delayInMilliseconds = delay * 1000;

  const [isButtonDisabled, setIsButtonDisabled] = useState(() => {
    const lastCheck = sessionStorage.getItem(sessionKey);
    return lastCheck && Date.now() - lastCheck < delayInMilliseconds;
  });

  const [count, setCount] = useState(() => {
    const lastCheck = sessionStorage.getItem(sessionKey);
    return lastCheck
      ? Math.max(delay - Math.floor((Date.now() - lastCheck) / 1000), 0)
      : delay;
  });

  useEffect(() => {
    if (isButtonDisabled) {
      const interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(interval);
            setIsButtonDisabled(false);
            sessionStorage.removeItem(sessionKey);
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isButtonDisabled]);

  function doAction() {
    onClick();
    setIsButtonDisabled(true);
    sessionStorage.setItem(sessionKey, Date.now());
    setCount(delay);
  }

  return (
    <React.Fragment>
      <button
        className="btn btn-outline-dark mt-2"
        onClick={() => doAction()}
        disabled={isButtonDisabled}
      >
        {label}
      </button>

      {isButtonDisabled && (
        <small className="d-block mt-2 fs-8">
          Aguarde <b>{count} segundos</b> para tentar novamente.
        </small>
      )}
    </React.Fragment>
  );
}

export default ButtonWithCount;

import React from 'react';
import SpringModal from '../../../../shared/Modal/Modal';
import { useEffect } from 'react';
import { enableTrial } from '../../../../services/Configuration';

function Welcome({ close }) {
  useEffect(() => {
    enableTrial();
  }, []);

  return (
    <SpringModal
      handleOpen={true}
      handleClose={() => close()}
      customStyleModal={{ maxWidth: 500, width: 'calc(100% - 16px)' }}
    >
      <div className="p-2">
        <h1 className="fs-1 fw-bold text-center">Bem-vindo!</h1>

        <p>
          Parabéns! Você ganhou
          <span className="fw-bold text-primary"> 2 meses</span> de cortesia
          para explorar todas as soluções que vão facilitar suas vendas e
          otimizar a rotina do seu dia a dia.
        </p>

        <p>
          Configure seu estabelecimento, personalize seu cardápio e comece a
          receber pedidos hoje mesmo!
        </p>

        <p>
          Se precisar de ajuda, nosso suporte exclusivo está disponível de
          segunda a sexta, das 9h às 18h. Para mais informações, acesse a aba
          'Suporte' no menu de navegação.
        </p>

        <button
          className="btn btn-primary w-100 fw-bold"
          onClick={() => close()}
        >
          Começar agora
        </button>
      </div>
    </SpringModal>
  );
}

export default Welcome;

import { saveInBrowserStorage } from '../Utils/Index';
import { httpService } from './HttpService';

export const loginConsumer = async (phone, password, recaptcha) => {
  const response = await httpService(
    'consumer/user',
    'login',
    { phone, password, recaptcha },
    true
  );
  if (Boolean(response) && response.success) {
    saveInBrowserStorage('USER_TOKEN', response.result.token, false);
    saveInBrowserStorage('USER_INFS', response.result, false);
  }
  return response;
};

export const authenticateByPhone = async (phone) => {
  const response = await httpService(
    'consumer/user',
    'authenticate',
    { phone },
    true
  );
  if (!response || !response.success) throw response;
  saveInBrowserStorage('USER_TOKEN', response.result.token, false);
  saveInBrowserStorage('USER_INFS', response.result, false);
  return response.result;
};

export const createConsumerAccount = async (registerInfs) => {
  const response = await httpService(
    'consumer/user',
    'create',
    { ...registerInfs },
    true
  );
  return response;
};

export const register = async (params) => {
  const response = await httpService(
    'consumer/user',
    'create',
    { ...params },
    true
  );
  return response;
};

export const verifyCoupon = async (couponDetails) => {
  const response = await httpService(
    'consumer/coupon',
    'verify',
    { ...couponDetails },
    true
  );
  return response;
};

export const verifyToken = async () => {
  const data = await httpService('consumer/user', 'checkToken', {}, true);
  return data;
};

export const getUserData = async () => {
  const data = await httpService('consumer/user', 'myProfile', {}, true);
  return data;
};

export const updatePassword = async (password) => {
  const data = await httpService(
    'consumer/user',
    'updatePassword',
    { ...password },
    true
  );
  return data;
};

export const updateAddress = async (address) => {
  const data = await httpService(
    'consumer/user',
    'updateAddress',
    { ...address },
    true
  );
  return data;
};

export const getHistoryOrders = async () => {
  const data = await httpService('consumer/store', 'myOrders', {}, true);
  return data;
};

export const getOrderDetail = async (cod_estabelecimento, order) => {
  const data = await httpService(
    'consumer/store',
    'order',
    { cod_estabelecimento, order },
    true
  );
  return data;
};

export const verifyStatusStore = async (cod_estabelecimento) => {
  const data = await httpService(
    'consumer/store',
    'checkStatusStore',
    { cod_estabelecimento },
    true
  );
  return data;
};

export const calculateDeliveryPrice = async (
  cod_estabelecimento,
  cep_cliente
) => {
  const data = await httpService(
    'consumer/fee',
    'calculate',
    { cod_estabelecimento, cep_cliente },
    true
  );
  return data;
};

export const cancelOrder = async (cod_pedido) => {
  const data = await httpService(
    'consumer/store',
    'cancelOrder',
    { cod_pedido },
    true
  );
  return data;
};

import React from 'react';
import SpringModal from '../../../../shared/Modal/Modal';

function OrderChangeStatusConfirmation({
  close,
  disableSubmitBtn,
  submitAction,
}) {
  return (
    <SpringModal handleOpen={true} handleClose={() => close()}>
      <div className="vstack align-items-center justify-content-center bg-white rounded shadow-lg p-2 gap-4">
        <span className="fs-4 fw-bold">Confirmar alteração:</span>
        <span className="fs-6 text-center">
          Após confirmar a ação não será possível reverter.
        </span>

        <div className="vstack gap-2 w-100 justify-content-between">
          <button
            className="btn btn-primary w-100"
            onClick={() => submitAction()}
            disabled={disableSubmitBtn}
          >
            Confirmar
          </button>

          <button className="btn btn-fill w-100" onClick={() => close()}>
            Cancelar
          </button>
        </div>
      </div>
    </SpringModal>
  );
}

export default OrderChangeStatusConfirmation;

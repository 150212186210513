import React, { useState, useEffect, useRef, useContext } from 'react';
import './Pedidos.scss';
import {
  aliveStoreHelth,
  getAllOrders,
  handlerStoreStatus,
} from '../../services/OrderManager';
import { toast } from 'react-toastify';
import { maskCurrencyBRL } from '../../Utils/Index';
import OrderInformation from './OrderInformation/OrderInformation';
import OrderCard from './OrderCard/OrderCard';
import SetupMenu from './SetupMenu/SetupMenu';
import FilterStatusOrder from './FilterStatusOrder/FilterStatusOrder';
import audioMP3 from '../../assets/bell.mp3';
import { OrderContext } from './OrderProvider/OrderProvider';
import useInterval from '../../shared/UseInterval/UseInterval';
import EventEmitter from '../../services/Event';
import OrdersModal from './OrdersModal/OrdersModal';
import SearchOrder from './OrderInformation/SearchOrder/SearchOrder';

export default function Pedidos() {
  const [infsOrders, setInfsOrders] = useState([]);
  const [infsOrdersOriginal, setInfsOrdersOriginal] = useState([]);
  const [orderDetails, setOrderDetails] = useState(undefined);
  const [textFilter, setTextFilter] = useState('');
  const [callOrders, setCallOrders] = useState(false);
  const [filterByStatusId, setFilterByStatusId] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(-1);

  const {
    enableAudioAlert,
    setTotalOrders,
    totalOrders,
    serviceHours,
    channel,
  } = useContext(OrderContext);
  const orderRef = useRef([]);
  const audio = new Audio(audioMP3);

  useInterval(() => callGetAllOrders(), callOrders ? 10000 : null);
  useInterval(() => aliveStoreHelth(), 120000); // 2 min

  useEffect(() => {
    aliveStoreHelth();
    EventEmitter.on('update-hours-opening', ({ openedNow }) => {
      setCallOrders(openedNow);
      emitStoreStatusEvent(openedNow);
      if (openedNow) callGetAllOrders();

      channel.onmessage = ({ data }) => {
        if (data.shouldGetKitchenOrders) emitStoreStatusEvent(openedNow);
      };
    });

    return () => {
      emitStoreStatusEvent(false);
      EventEmitter.off('update-hours-opening');
      channel.close();
      setCallOrders(false);
      if (serviceHours.openedNow) handlerStoreStatus(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const emitStoreStatusEvent = (isOpen) => {
    channel.postMessage({ isOpen });
  };

  async function callGetAllOrders() {
    const today = new Date();
    const yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);
    const { dtStart, dtEnd } = getDateRange();

    getAllOrders(dtStart, dtEnd)
      .then((orders) => {
        handleStoreOrders(orders);
      })
      .catch((error) => {
        console.error('ORDER_ERROR', error);
        toast.error(
          'Houve um problema ao buscar os pedidos, por favor entre em contato com nosso suporte.'
        );
      });
  }

  function getDateRange() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);
    return {
      dtStart: `${yesterday.toISOString().split('T')[0]} 00:00:00`,
      dtEnd: `${today.toISOString().split('T')[0]} 23:59:59`,
    };
  }

  function handleStoreOrders(orders) {
    if (!orders?.length) return;
    const items = orders.map((order) => {
      if (order.edited) return order;
      order.clientName = getNormalizedClientName(order.nome);
      order.edited = true;
      order.selected = false;
      order.force_selected = false;
      order.valor_total = maskCurrencyBRL(
        order.valor_total_com_desconto
          ? order.valor_total_com_desconto
          : order.valor_total
      );
      order.dh_criacao = getMinutes(order.dh_criacao);
      order.dh_inicio = getMinutes(order.dh_inicio);
      order.dh_concluido = getMinutes(order.dh_concluido);
      order.start_time = order.dh_criacao;
      order.payment_method =
        order.pagamento_grupo === 'Outros'
          ? order.pagamento_tipo
          : `${order.pagamento_grupo}-${order.pagamento_tipo}`;
      order.color_status = getStatusColor(order.cod_pedido_status);
      return order;
    });
    setInfsOrdersOriginal(items);
    shouldPlayAlert();
    if (textFilter.length || filterByStatusId?.id) {
      filterOrders(textFilter, filterByStatusId, false);
      return;
    }
    setInfsOrders(items);
  }

  function getNormalizedClientName(name) {
    if (!name?.length) return '';
    return name
      .toLocaleLowerCase()
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '');
  }

  function getMinutes(orderDate) {
    if (!orderDate) return '--:--';
    return new Date(orderDate).toLocaleTimeString('pt-BR', {
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  function getStatusColor(idStatus) {
    const statusColor = {
      1: '#949494',
      2: '#0737E3',
      3: '#FF6B00',
      4: '#FF6B00',
      5: '#27AE60',
    };
    if (statusColor[idStatus]) return statusColor[idStatus];
    return '#000000';
  }

  function shouldPlayAlert() {
    if (!enableAudioAlert) return;
    if (infsOrdersOriginal.length <= totalOrders) return;
    setTotalOrders(infsOrdersOriginal.length);
    audio.play().catch((error) => console.log('Audio playback failed:', error));
  }

  function filterOrders(text, status, forceFilter = false) {
    if (textFilter === text && !forceFilter) return;
    let options = infsOrdersOriginal;
    if (status.id)
      options = options.filter(
        (order) =>
          order.cod_pedido_status === status.id ||
          order.cod_pedido_status === status.subId
      );

    if (text.length)
      options = options.filter((item) => getOrderByNameOrCode(item, text));
    setTextFilter(text);
    setFilterByStatusId(status);
    setInfsOrders(options);
  }

  function getOrderByNameOrCode(order, textToSearch) {
    // eslint-disable-next-line eqeqeq
    if (order.codigo == textToSearch) return true;
    if (order.clientName.includes(textToSearch)) return true;
    return false;
  }

  function handlerOrderSelected(order, index) {
    infsOrders[index].selected = true;
    setInfsOrders(infsOrders);
    setSelectedIndex(index);
    setOrderDetails(order);
  }

  function resetOrderSelected() {
    infsOrders[selectedIndex].selected = false;
    setInfsOrders(infsOrders);
    setSelectedIndex(-1);
    setOrderDetails(null);
  }

  function updateOrderStatus(updatedOrder) {
    const updatedOrders = infsOrdersOriginal.map((order) => {
      if (order.codigo === updatedOrder.codigo) order = updatedOrder;
      return order;
    });
    handleStoreOrders(updatedOrders);
    setTimeout(() => {
      orderRef.current[updatedOrder.codigo]?.scrollIntoView();
    }, 500);
  }

  return (
    <div className="p-0 m-0 vstack position-relative justify-content-start align-items-center">
      <div className="container-sm h-100 pb-3">
        <SetupMenu />

        <FilterStatusOrder
          onSelectFilter={(status) => filterOrders(textFilter, status, true)}
        />

        <div className="p-2 rounded-1 shadow-sm">
          {callOrders && (
            <div className="bg-light d-flex flex-column flex-md-row gap-1 rounded w-100">
              <div className="gap-1 hstack">
                <span className="fs-7">Dia da semana:</span>
                <span className="fs-7 fw-bold">{serviceHours.currentDay}</span>
              </div>
              <div className="gap-1 hstack">
                <span className="fs-7">Horário de funcionamento:</span>
                <span className="fs-7 fw-bold">
                  {serviceHours.timeToOpen} às {serviceHours.timeToClose} hrs
                </span>
              </div>
            </div>
          )}

          <div className="align-items-start gap-md-3 hstack justify-content-center w-100">
            <div className="w-100 h-100">
              <div className="w-100 vstack h-100">
                <SearchOrder
                  onSearchOrder={(text) => filterOrders(text, filterByStatusId)}
                />

                <div className="d-flex flex-column">
                  {infsOrders?.length ? (
                    infsOrders.map((order, index) => {
                      return (
                        <div
                          key={order.codigo}
                          ref={(element) =>
                            orderRef.current.splice(order.codigo, 1, element)
                          }
                        >
                          <OrderCard
                            infsOrder={order}
                            orderToDisplay={(params) =>
                              handlerOrderSelected(params, index)
                            }
                          />
                        </div>
                      );
                    })
                  ) : (
                    <div className="vstack w-100 align-items-center">
                      <h5 className="fw-bold fs-5 mb-1">
                        Não há pedidos disponíveis.
                      </h5>
                      <p>
                        {textFilter.length > 0 || filterByStatusId
                          ? 'Tente resetar os filtros de pesquisa ou aguarde até um novo pedido.'
                          : 'Aguarde até um novo pedido chegar.'}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {Boolean(orderDetails) && (
              <OrdersModal handleOpen={true}>
                <OrderInformation
                  order={orderDetails}
                  updateOrderStatus={(params) => updateOrderStatus(params)}
                  close={() => resetOrderSelected()}
                />
              </OrdersModal>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useContext, useEffect, useState } from 'react';
import { IconPaymentMethod } from '../../../Utils/IconPaymentMethod';
import './OrderInformation.scss';
import {
  acceptOrder,
  concludedOrder,
  declineOrder,
  setStatusOrder,
} from '../../../services/OrderManager';
import { toast } from 'react-toastify';
import { maskCurrencyBRL } from '../../../Utils/Index';
import { OrderContext } from '../OrderProvider/OrderProvider';
import OrderChangeStatusConfirmation from './OrderChangeStatusConfirmation/OrderChangeStatusConfirmation';
import PrintOrderReceipt from './PrintOrderReceipt/PrintOrderReceipt';
import OrderCoupon from './OrderCoupon/OrderCoupon';
import OrderDeliveryType from './OrderDeliveryType/OrderDeliveryType';
import OrderClientInfos from './OrderClientInfos/OrderClientInfos';
import OrderProducts from './OrderProduts/OrderProducts';
import OrderHeader from './OrderHeader/OrderHeader';

export default function OrderInformation({ order, updateOrderStatus, close }) {
  const [orderDetails, setOrderDetails] = useState(null);
  const [buttons, setButtons] = useState({ primary: '', secondary: '' });
  const [displayChangeStatusConfirmation, setDisplayChangeStatusConfirmation] =
    useState(false);
  const [disablebutton, setDisablebutton] = useState(false);
  const [isCancelOrder, setIsCancelOrder] = useState(true);

  const { alertOnChangeStatus } = useContext(OrderContext);

  useEffect(() => {
    handleOrder(order.items);
    setButtonsLabel(order.cod_pedido_status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOrder() {
    order.prices = {
      total: maskCurrencyBRL(order.valor_total_com_desconto),
      trade: maskCurrencyBRL(order.pagamento_troco),
      coupon: maskCurrencyBRL(order.valor_desconto),
      delivery: maskCurrencyBRL(order.valor_entrega),
    };
    order.items = order.items.map((product) => {
      product.preco = maskCurrencyBRL(product.preco);
      product.subtotal = maskCurrencyBRL(product.subtotal);
      product.complementos.forEach((complement) => {
        complement.preco = maskCurrencyBRL(complement.preco);
      });
      return product;
    });
    setOrderDetails(order);
  }

  async function callSetStatusOrder() {
    const functions = {
      1: (idOrder) => acceptOrder(idOrder),
      2: (idOrder) => setStatusOrder(idOrder, orderDetails.entrega),
      3: (idOrder) => concludedOrder(idOrder),
      4: (idOrder) => concludedOrder(idOrder),
      6: (idOrder) => declineOrder(idOrder, false),
    };

    const orderStatusId = orderDetails.cod_pedido_status;
    if (functions[orderStatusId]) {
      functions[orderStatusId](orderDetails.codigo)
        .then((result) => {
          toast.success(result.message);
          updateOrder(
            orderDetails.codigo,
            result.status,
            orderDetails.historico
          );
        })
        .catch((error) => {
          console.error('ORDER_ERROR', error);
          toast.error(
            'Erro ao alterar status do pedido, por favor tente novamente ou entre em contato conoso.'
          );
        })
        .finally(() => {
          setDisablebutton(false);
          setDisplayChangeStatusConfirmation(false);
        });
    }
  }

  async function cancelOrder(order) {
    declineOrder(order.codigo, order.cod_pedido_status === 1)
      .then((result) => {
        toast.message(result.message);
        updateOrder(order.codigo, result.status, orderDetails.historico);
      })
      .catch((error) => {
        console.error('CANCEL_ORDER_ERROR', error);
        toast.error('Ocorreu um erro ao cancelar o pedido');
      })
      .finally(() => {
        setDisplayChangeStatusConfirmation(false);
        setDisablebutton(false);
      });
  }

  function setButtonsLabel(idOrderStatus) {
    const buttonLabels = {
      1: { primary: 'Aceitar pedido', secondary: 'Recusar pedido' },
      2: {
        primary: 'Liberar pedido para entrega/retirada',
        secondary: 'Cancelar pedido',
      },
      3: { primary: 'Finalizar pedido', secondary: 'Cancelar pedido' },
      4: { primary: 'Finalizar pedido', secondary: 'Cancelar pedido' },
      5: { primary: 'Pedido concluído', secondary: 'Cancelar pedido' },
      6: { primary: 'Cancelar pedido', secondary: 'Cancelar pedido' },
    };

    if (buttonLabels[idOrderStatus]) {
      setButtons(buttonLabels[idOrderStatus]);
    } else
      setButtons({ primary: 'Pedido cancelado', secondary: 'Cancelar pedido' });
  }

  function changeStatusOrder(isCancelOrder = false) {
    setIsCancelOrder(isCancelOrder);
    if (alertOnChangeStatus) {
      setDisplayChangeStatusConfirmation(true);
      return;
    }
    setDisablebutton(true);
    if (isCancelOrder) cancelOrder(orderDetails);
    else callSetStatusOrder(orderDetails, true);
  }

  function updateOrder(idOrder, newIdStatusOrder, historyOrder) {
    const orderUpdated = {
      ...orderDetails,
      ...updateStatusOrder(idOrder, newIdStatusOrder, historyOrder),
    };
    setOrderDetails(orderUpdated);
    updateOrderStatus(orderUpdated);
    setButtonsLabel(orderUpdated.cod_pedido_status);
  }

  function updateStatusOrder(idOrder, idNewStatusOrder, historyStatusOrder) {
    let status,
      timeStatus = '';
    const time = new Date().toISOString();
    const brlDate = new Date()
      .toLocaleString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(',', '');

    switch (idNewStatusOrder) {
      case 2:
        status = 'Em preparo';
        timeStatus = { dh_inicio: time };
        break;
      case 3:
        status = 'Pronto para retirada';
        break;
      case 4:
        status = 'Saiu para entrega';
        break;
      case 5:
        status = 'Finalizado';
        timeStatus = { dh_concluido: time };
        break;
      case 6:
        status = 'Cancelado pelo cliente';
        timeStatus = { dh_concluido: time };
        break;
      case 7:
        status = 'Cancelado pelo estabelecimento';
        timeStatus = { dh_concluido: time };
        break;
      case 8:
        status = 'Recusado pelo estabelecimento';
        timeStatus = { dh_concluido: time };
        break;
      default:
        break;
    }

    historyStatusOrder.unshift({
      status: status,
      cod_pedido_status: idNewStatusOrder,
      datahora: time,
      time_formated: brlDate,
    });

    return {
      timeStatus,
      status,
      codigo: idOrder,
      cod_pedido_status: idNewStatusOrder,
      historico: historyStatusOrder,
      last_status: { status: status, time: brlDate },
      edited: false,
      force_selected: true,
    };
  }

  return (
    <div className="order-information align-items-center overflow-y-auto vstack w-100 h-100">
      <div className="bg-white d-flex flex-column h-100 order-information-content position-relative shadow-sm w-100">
        {orderDetails && (
          <React.Fragment>
            <OrderHeader order={orderDetails} close={() => close()} />

            <div className="p-2 pt-0">
              <div
                className="hstack align-items-center justify-content-start position-relative w-100 gap-2 mb-2"
                style={{ height: 38 }}
              >
                <PrintOrderReceipt
                  order={orderDetails}
                  prices={orderDetails.prices}
                />

                <OrderDeliveryType
                  isDelivery={orderDetails.entrega}
                  deliveryPrice={orderDetails.prices.delivery}
                />

                <div className="hstack gap-2">
                  {orderDetails.cupom && (
                    <OrderCoupon
                      coupon={orderDetails.cupom}
                      discount={orderDetails.prices.coupon}
                    />
                  )}

                  <div className="w-100 hstack align-items-center gap-1">
                    <IconPaymentMethod
                      idPayment={orderDetails.cod_tipo_pagto}
                      width={36}
                      height={27}
                    />
                    <span className="fs-8 w-75 d-block">
                      {orderDetails.payment_method}
                    </span>
                  </div>
                </div>
              </div>

              <OrderProducts products={order.items} />

              {orderDetails.obs && (
                <div className="p-2 bg-light rounded mb-2 border shadow-sm vstack">
                  <h5 className="fw-bold mb-2 text-primary">Obersavação: </h5>
                  <span className="fs-6 text-black">{orderDetails.obs}</span>
                </div>
              )}

              <OrderClientInfos order={orderDetails} />

              <div className="vstack w-100 gap-2">
                <div className="hstack w-100 justify-content-center gap-4">
                  <div className="fs-5 hstack gap-1 align-items-center fw-bold">
                    <span className="fs-6">Cobrar do cliente:</span>
                    <span className="text-success">
                      {orderDetails.prices.total}
                    </span>
                  </div>

                  {orderDetails.cod_tipo_pagto === 15 && (
                    <div className="fs-6 hstack gap-1 align-items-center">
                      <span className="fs-8">Troco:</span>
                      <span className="fw-bold text-success">
                        {orderDetails.prices.trade}
                      </span>
                    </div>
                  )}
                </div>

                <button
                  className={`btn btn-primary w-100 ${
                    orderDetails.cod_pedido_status >= 5
                      ? 'pe-none opacity-50'
                      : ''
                  }`}
                  disabled={orderDetails.cod_pedido_status >= 5}
                  onClick={() => changeStatusOrder()}
                >
                  {buttons.primary}
                </button>

                <button
                  className={`btn w-100 ${
                    orderDetails.cod_pedido_status >= 5
                      ? 'pe-none opacity-50'
                      : 'btn btn-outline-dark'
                  } `}
                  onClick={() => changeStatusOrder(true)}
                  disabled={
                    disablebutton || orderDetails.cod_pedido_status >= 5
                  }
                >
                  {buttons.secondary}
                </button>
              </div>
            </div>
          </React.Fragment>
        )}
      </div>

      {displayChangeStatusConfirmation && (
        <OrderChangeStatusConfirmation
          submitAction={() => {
            if (isCancelOrder) {
              cancelOrder(orderDetails);
              setDisplayChangeStatusConfirmation(false);
              return;
            }
            setDisablebutton(true);
            callSetStatusOrder(orderDetails);
            setDisplayChangeStatusConfirmation(false);
          }}
          close={() => setDisplayChangeStatusConfirmation(false)}
          disableSubmitBtn={disablebutton}
        />
      )}
    </div>
  );
}

import React from 'react';

function OrderCoupon({ coupon, discount }) {
  return (
    <button className="order-tooltip text-dark bg-warning rounded-3 hstack px-2 btn btn-primary-fill p-0 position-relative">
      <span className="material-symbols-outlined">redeem</span>

      <div className="order-tooltip-content" style={{ top: 40 }}>
        <div className="vstack text-left w-100 align-items-start">
          <span className="fs-7">Cupom Utilizado:</span>
          <span className="fs-7 fw-bold">{coupon}</span>
        </div>
        <div className="vstack text-left w-100 align-items-start">
          <span className="fs-7">Desconto aplicado:</span>
          <span className="fs-7 fw-bold">{discount}</span>
        </div>
      </div>
    </button>
  );
}

export default OrderCoupon;

import { httpService } from './HttpService';

export const getStoreOptions = async () => {
  const data = await httpService('painel/gestor', 'getStoreOptions', {});
  return data;
};

export const handlerSoundAlert = async (status) => {
  const data = await httpService('painel/gestor', 'setSoundAlert', { status });
  return data;
};

export const handlerAutoPrint = async (status) => {
  const data = await httpService('painel/gestor', 'setAutoPrint', { status });
  return data;
};

export const handlerStoreStatus = async (status) => {
  const data = await httpService('painel/gestor', 'setStoreStatus', { status });
  return data;
};

export const handlerConfirmationModal = async (status) => {
  const data = await httpService('painel/gestor', 'setAlertOnChangeStatus', {
    status,
  });
  return data;
};

export const getQtyOrdersDay = async (dh_inicio, dh_fim) => {
  const data = await httpService('painel/gestor', 'getQtyOrdersDay', {
    dh_inicio,
    dh_fim,
  });
  return data;
};

export const getAllOrders = async (dh_inicio, dh_fim) => {
  const response = await httpService('painel/gestor', 'getAllOrders', {
    dh_inicio,
    dh_fim,
  });
  if (!response || !response.success || !response.result) throw response;
  return response.result;
};

export const getOrdersMonitor = async () => {
  const response = await httpService(
    'painel/gestor',
    'getOrdersMonitor',
    null,
    false,
    'get'
  );
  if (!response || !response.success || !response.results) throw response;
  return response.results;
};

export const getStoreStatus = async () => {
  const response = await httpService(
    'painel/gestor',
    'storeStatus',
    null,
    false,
    'get'
  );
  if (!response || !response.success || !response.result) throw response;
  return response.result;
};

export const getOrderDetailsById = async (order) => {
  const response = await httpService('painel/gestor', 'getOrder', { order });
  if (!response || !response.success || !response.result) throw response;
  return response.result;
};

export const acceptOrder = async (order) => {
  const response = await httpService('painel/gestor', 'acceptOrder', { order });
  if (!response || !response.success || !response.result) {
    response.message = response.msg
      ? response.msg
      : 'Ocorreu um erro ao aceitar o pedido';
    throw response;
  }
  response.result.message = 'Pedido aceito';
  return response.result;
};

export const declineOrder = async (order, is_decline) => {
  const response = await httpService('painel/gestor', 'declineOrder', {
    order,
    is_decline,
  });
  if (!response || !response.success || !response.result) {
    response.message = response.msg
      ? response.msg
      : 'Ocorreu um erro ao atualizar o status do pedido';
    throw response;
  }
  response.result.message = `Pedido ${order === 1 ? 'recusado' : 'cancelado'}.`;
  return response.result;
};

export const setStatusOrder = async (order, isDelivery) => {
  const response = await httpService('painel/gestor', 'setStatusOrder', {
    order,
  });
  if (!response || !response.success || !response.result) {
    response.message = response.msg
      ? response.msg
      : 'Ocorreu um erro ao atualizar o status do pedido';
    throw response;
  }
  response.result.message = `Pedido liberado para ${
    isDelivery ? 'entrega' : 'retirada'
  }`;
  response.result.status = response.result.novoStatus;
  return response.result;
};

export const concludedOrder = async (order) => {
  const response = await httpService('painel/gestor', 'concludedOrder', {
    order,
  });
  if (!response || !response.success || !response.result) {
    response.message = response.msg
      ? response.msg
      : 'Ocorreu um erro ao atualizar o status do pedido';
    throw response;
  }
  return response.result;
};

export const updateOpeningHours = async (hours) => {
  const data = await httpService('painel/gestor', 'updateHoursOpen', {
    ...hours,
  });
  return data;
};

export const aliveStoreHelth = async () => {
  const data = await httpService('painel/gestor', 'aliveStoreHelth ', {});
  return data;
};

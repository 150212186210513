import React, { useContext, useEffect, useRef, useState } from 'react';
import useInterval from '../../shared/UseInterval/UseInterval';
import { OrderContext } from '../Pedidos/OrderProvider/OrderProvider';
import KitchenOrderCard from './KitchenOrderCard/KitchenOrderCard';
import OrderMonitorEmptyState from './OrderMonitorEmptyState/OrderMonitorEmptyState';
import {
  getOrdersMonitor,
  getStoreStatus,
  setStatusOrder,
} from '../../services/OrderManager';
import { toast } from 'react-toastify';

export default function KitchenOrderMonitor() {
  const { channel } = useContext(OrderContext);
  const canGetOrders = useRef(false);

  const [orders, setOrders] = useState([]);
  const [errorOnLoad, setErrorOnLoad] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  useInterval(
    () => getOrders(),
    canGetOrders.current && !errorOnLoad ? 10000 : null
  );

  useEffect(() => {
    subscribeChannel();
    verifyBroadCastConnection();
    return () => channel.close();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function subscribeChannel() {
    channel.onmessage = ({ data }) => {
      if (data.isOpen === canGetOrders.current) return;
      if (data.isOpen) onStoreIsOpen();
      else canGetOrders.current = false;
    };
  }

  function verifyBroadCastConnection() {
    channel.postMessage({ shouldGetKitchenOrders: true });
  }

  function verifyStoreStatus() {
    getStoreStatus()
      .then((status) => {
        if (status) onStoreIsOpen();
        else canGetOrders.current = false;
      })
      .catch((error) => console.error('STORE_STATUS_ERROR', error));
  }

  function onStoreIsOpen() {
    setisLoading(true);
    setErrorOnLoad(false);
    getOrders();
    canGetOrders.current = true;
  }

  function getOrders() {
    getOrdersMonitor()
      .then((orders) => {
        setOrders(orders);
        verifyBroadCastConnection();
      })
      .catch((error) => {
        setErrorOnLoad(true);
        setOrders([]);
        console.error('ORDER_MONITOR_ERROR', error);
      })
      .finally(() => setisLoading(false));
  }

  function handleOrderReady(orderId) {
    setStatusOrder(orderId)
      .then(() => {
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.codigo !== orderId)
        );
        toast.success('Pedido marcado como pronto.');
      })
      .catch((error) => {
        toast.error('Erro ao marcar pedido como pronto.');
        console.error('MONITOR_UPDATE_ORDER_ERROR', error);
      });
  }

  return (
    <div className="p-3">
      <h3 className="text-primary fw-bold">Monitor de Pedidos</h3>
      <h5 className="m-0 py-2 pt-1">
        Visualize os pedidos que precisam ser sendo preparados.
      </h5>

      <OrderMonitorEmptyState
        isLoading={isLoading}
        errorOnLoad={errorOnLoad}
        storeIsOpen={canGetOrders.current}
        isEmpty={!orders.length}
        checkStoreStatus={() => verifyStoreStatus()}
        retryLoadOrders={() => getOrders()}
      >
        <div className="row">
          {orders.map((order) => (
            <KitchenOrderCard
              key={order.codigo}
              order={order}
              onCompleted={(orderID) => handleOrderReady(orderID)}
            />
          ))}
        </div>
      </OrderMonitorEmptyState>
    </div>
  );
}

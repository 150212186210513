import React, { useState } from 'react';
import OutsideClick from '../OutsideClick/OutsideClick';

function DropDownV2({ active, itens, label, onSelect }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOption(item) {
    onSelect(item);
    setIsOpen(false);
  }

  return (
    <OutsideClick
      onClickOutside={() => setIsOpen(false)}
      listenOutsideEvent={isOpen}
    >
      <div className="dropdown w-100">
        <button
          className="btn btn-outline-dark dropdown-toggle w-100 bg-transparent text-dark"
          onClick={() => setIsOpen(!isOpen)}
        >
          {active[label]}
        </button>
        {isOpen && (
          <div className="dropdown-menu show text-center w-100 p-0">
            {itens.map((item, index) => (
              <button
                key={index}
                className={`dropdown-item ${
                  itens.length - 1 === index ? '' : 'border-bottom'
                } ${
                  item.id === active.id ? 'bg-body-secondary fw-bolder' : ''
                }`}
                onClick={() => handleOption(item)}
              >
                {item[label]}
              </button>
            ))}
          </div>
        )}
      </div>
    </OutsideClick>
  );
}

export default DropDownV2;

import React, { useContext, useEffect, useRef, useState } from 'react';
import { OrderContext } from '../../OrderProvider/OrderProvider';
import { useReactToPrint } from 'react-to-print';
import OrderReceipt from '../../OrderReceipt/OrderReceipt';

function PrintOrderReceipt({ order, prices }) {
  const componentRef = useRef();
  const { autoPrintOrderReceipt } = useContext(OrderContext);

  const [print, setPrint] = useState(false);
  const [enablePrint, setEnablePrint] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadedReceiptComponent, setLoadedReceiptComponent] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onAfterPrint: () => {
      setPrint(false);
      setIsLoading(false);
      setEnablePrint(true);
    },
  });

  useEffect(() => {
    const enable = order?.cod_pedido_status > 1 && order?.cod_pedido_status < 5;
    setEnablePrint(enable);
    if (enable && autoPrintOrderReceipt && loadedReceiptComponent)
      handlePrint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, autoPrintOrderReceipt, loadedReceiptComponent]);

  function generateOrderReceipt() {
    setPrint(true);
    setEnablePrint(false);
    setIsLoading(true);
    handlePrint();
  }

  function onLoadRefComponent(ref) {
    componentRef.current = ref;
    setLoadedReceiptComponent(true);
  }

  return (
    <React.Fragment>
      <button
        className={`align-items-center btn btn-primary gap-3 hstack justify-content-center ${
          !enablePrint ? 'pe-none opacity-50' : ''
        }`}
        disabled={!enablePrint || print}
        onClick={() => generateOrderReceipt(false)}
      >
        <div className="hstack gap-2">
          <span
            className={`${
              isLoading
                ? 'spinner-border spinner-border-sm'
                : 'material-symbols-outlined'
            }`}
          >
            {isLoading ? '' : 'print'}
          </span>
          <span>{isLoading ? 'Carregando' : 'Imprimir'}</span>
        </div>
      </button>

      <div style={{ display: 'none' }}>
        <OrderReceipt ref={onLoadRefComponent} order={order} prices={prices} />
      </div>
    </React.Fragment>
  );
}

export default PrintOrderReceipt;

import React, { useState, useEffect } from 'react';
import DropDownV2 from '../../../shared/DropDownV2/DropDownV2';

export default function FilterStatusOrder({ onSelectFilter }) {
  const filters = [
    { id: 0, subId: 0, cancelStatus: 0, name: 'Todos' },
    { id: 1, subId: 0, cancelStatus: 0, name: 'Aguardando' },
    { id: 2, subId: 0, cancelStatus: 0, name: 'Em preparo' },
    { id: 9, subId: 0, cancelStatus: 0, name: 'Cozinha - Liberado' },
    { id: 3, subId: 4, cancelStatus: 0, name: 'Liberado (Entrega/Retirada)' },
    { id: 5, subId: 0, cancelStatus: 0, name: 'Finalizado' },
    { id: 6, subId: 7, cancelStatus: 8, name: 'Cancelado' },
  ];

  const [activeFilter, setActiveFilter] = useState(filters[0]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  function handleProductStatusFilter(filter) {
    onSelectFilter(filter);
    setActiveFilter(filter);
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <div className="d-flex align-items-center justify-content-center vstack w-100">
          <DropDownV2
            itens={filters}
            active={activeFilter}
            onSelect={(filter) => handleProductStatusFilter(filter)}
            label="name"
          />
        </div>
      ) : (
        <div className="d-md-flex m-0 mb-md-3">
          <div className="w-100 hstack justify-content-between rounded shadow-sm p-2 align-items-center">
            {filters.map((filter) => {
              return (
                <button
                  key={filter.id}
                  className={`btn btn-primary-fill fs-6 w-100 h-100 align-items-center justify-content-center p-0 m-0 ${
                    filter.id === activeFilter.id
                      ? 'bg-primary text-white fw-bold'
                      : ''
                  }`}
                  onClick={() => handleProductStatusFilter(filter)}
                >
                  {filter.name}
                </button>
              );
            })}
          </div>
        </div>
      )}
    </React.Fragment>
  );
}

import React, { useState, useEffect, useContext } from 'react';
import { OrderContext } from '../OrderProvider/OrderProvider';

const OrderReceipt = React.forwardRef((props, ref) => {
  const { storeName } = useContext(OrderContext);

  const [order, setOrder] = useState(undefined);

  useEffect(() => {
    handleOrder();
  }, [props]); //eslint-disable-line react-hooks/exhaustive-deps

  function handleOrder() {
    props.order.dtCreated = isoStringToBrlDate(props.order);
    props.order.payment = orderPrices(props.order);
    setOrder(props.order);
  }

  function isoStringToBrlDate(date) {
    return new Date(date)
      .toLocaleString('pt-BR', {
        hour: '2-digit',
        minute: '2-digit',
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })
      .replace(',', '');
  }

  function orderPrices() {
    const { order, prices } = props;
    const payment = order.pagamento_grupo.toLowerCase().includes('outros')
      ? order.payment_method
      : `${order.payment_method} - ${order.pagamento_tipo}`;

    return [
      { label: 'Pagamento', value: payment },
      { label: 'Desconto', value: prices.coupon },
      { label: 'Taxa de entrega', value: prices.delivery },
      { label: 'Troco', value: prices.trade },
      { label: 'Total', value: prices.total },
    ];
  }

  return order ? (
    <div ref={ref} className="container border p-4 mt-3">
      <h4 className="mb-3">{storeName}</h4>
      <div className="d-flex justify-content-between border-bottom pb-2 mb-3">
        <span>
          Pedido #{order.codigo} • {order.entrega ? 'Entrega' : 'Retirada'}
        </span>
        <span>Horário: {order.last_status.time}</span>
      </div>

      <h5 className="mt-3">Itens:</h5>
      <ul className="list-group mb-3">
        {order.items.map((item, index) => (
          <li key={index} className="list-group-item">
            <strong>
              {item.qtd}x {item.nome}
            </strong>{' '}
            - {item.preco}
            {item.complementos.length > 0 && (
              <ul className="mt-2">
                {item.complementos.map((comp, idx) => (
                  <li key={idx}>
                    {comp.grupo}: {comp.nome} - {comp.preco}
                  </li>
                ))}
              </ul>
            )}
          </li>
        ))}
      </ul>

      <h5 className="mt-3">Dados do Cliente:</h5>
      <p>
        {order.nome}
        <br />
        {order.endereco_rua}, {order.endereco_nro} -{' '}
        {order.endereco_complemento}
        <br />
        CEP: {order.cep_entrega}, {order.endereco_bairro},{' '}
        {order.endereco_cidade} / {order.endereco_uf}
      </p>

      <h5 className="mt-3">Pagamento:</h5>
      <ul className="list-group mb-3">
        {order.payment.map((pay, index) => (
          <li
            key={index}
            className="list-group-item d-flex justify-content-between"
          >
            <span>{pay.label}</span>
            <strong>{pay.value}</strong>
          </li>
        ))}
      </ul>
    </div>
  ) : null;
});

export default OrderReceipt;

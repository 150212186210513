import React, { useContext, useEffect, useState } from 'react';
import EventEmitter from '../../../services/Event';
import AccountInfos from './AccountInfos';
import { userDataContext } from '../../MenuProvider/MenuProvider';

function AddressToDelivery({ authenticateUser, openOrderHistory }) {
  const { userData } = useContext(userDataContext);
  const [openModalUserData, setOpenModalUserData] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [address, setAddress] = useState({});

  useEffect(() => {
    if (userData?.address) setAddress(userData.address);
    setAuthenticated(Boolean(userData));
  }, [userData]);

  useEffect(() => {
    EventEmitter.on('update-address', (address) =>
      setTimeout(() => setAddress(address), 75)
    );
    return () => EventEmitter.off('update-address');
  }, []);

  return (
    <>
      <div className="z-2 position-fixed w-100 bg-body-secondary hstack justify-content-between align-items-center p-2 py-1">
        {authenticated ? (
          <React.Fragment>
            <div className="hstack gap-1 w-50">
              <span className="fs-8 text-truncate">
                {address?.street}, {address?.number}
              </span>
              <button
                className="btn btn-primary-fill p-0 m-0 hstack bg-dark bg-opacity-75 p-1 rounded-4"
                onClick={() => setOpenModalUserData(true)}
              >
                <span className="material-symbols-outlined fs-6 text-white">
                  person
                </span>
              </button>
            </div>

            <button
              className="btn btn-primary-fill p-0 m-0 hstack gap-1"
              onClick={() => openOrderHistory()}
            >
              <span className="fs-8">Histórico de pedidos</span>
              <span className="material-symbols-outlined fs-6 text-warning hstack bg-dark bg-opacity-75 p-1 rounded-4">
                history
              </span>
            </button>
          </React.Fragment>
        ) : (
          <div className="w-100 hstack justify-content-end">
            <button
              className="btn btn-primary-fill fs-7 fw-bold hstack m-0 p-0"
              onClick={() => authenticateUser()}
            >
              <span className="material-symbols-outlined">account_circle</span>
            </button>
          </div>
        )}
      </div>

      {authenticated && openModalUserData && (
        <AccountInfos close={() => setOpenModalUserData(false)} />
      )}
    </>
  );
}

export default AddressToDelivery;

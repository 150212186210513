import React from 'react';
import OrderStatusHistory from '../OrderStatusHistory/OrderStatusHistory';

function OrderHeader({ order, close }) {
  return (
    <div className="d-flex flex-column">
      <div
        className="p-2 align-items-center bg-white hstack justify-content-betweeen position-fixed w-100 z-3"
        style={{ maxWidth: 768 }}
      >
        <span className="d-block fs-3 fw-bold text-center w-100">
          Detalhes do pedido{' '}
          <span className="text-primary">#{order.codigo}</span>
        </span>

        <div className="bg-dark bg-opacity-25 d-flex p-2 rounded-5">
          <button
            type="button"
            className="btn-close p-0"
            aria-label="Close"
            onClick={() => close()}
          ></button>
        </div>
      </div>

      <div className="pt-5 mt-2 p-2">
        <OrderStatusHistory order={order} />
      </div>
    </div>
  );
}

export default OrderHeader;

import React from 'react';

function OrderClientInfos({ order }) {
  return (
    <div className="p-2 bg-light rounded mb-2 border shadow-sm">
      <h5 className="fw-bold mb-2 text-primary">Dados do cliente:</h5>
      <div className="vstack">
        <span className="fs-7 text-black letter-spacing">{order.nome}</span>
        <span className="fs-7 text-black fw-bold letter-spacing">
          {order.endereco_rua}, {order.endereco_nro} - {order.endereco_bairro} -{' '}
          {order.endereco_cidade}/{order.endereco_uf}
        </span>
        <span className="fs-7 text-black fw-bold letter-spacing">
          CEP: {order.endereco_cep}
        </span>
      </div>
    </div>
  );
}

export default OrderClientInfos;

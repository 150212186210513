import { useRef, useState } from 'react';
import OutsideClick from '../OutsideClick/OutsideClick';
import './TimerPicker.scss';

function TimerPicker({ options, onChange, selected }) {
  const listRef = useRef(null);

  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(selected);
  const [openUpwards, setOpenUpwards] = useState(false);

  function handleOpen() {
    if (open) return;
    if (listRef.current) {
      const rect = listRef.current.getBoundingClientRect();
      const shouldOpenUpwards =
        rect.bottom + window.innerHeight / 2 >= window.innerHeight;
      setOpenUpwards(shouldOpenUpwards);
    }
    setOpen(true);
  }

  function onSelectTime(item) {
    setActive(item);
    onChange(item);
    setOpen(false);
  }

  return (
    <OutsideClick
      listenOutsideEvent={open}
      onClickOutside={() => setOpen(false)}
      onRef={(ref) => (listRef.current = ref.current)}
    >
      <ul
        className="list-group list-group-flush time-picker"
        onClick={() => handleOpen()}
      >
        <span>{active}</span>
        {open && (
          <div
            className={`time-picker__list-container ${
              openUpwards ? 'bottom-0 mb-4' : 'top-0 mt-4'
            }`}
          >
            {options.map((item) => (
              <li
                key={item}
                value={item}
                className={`list-group-item time-picker__list-container-option ${
                  item === active ? 'bg-success text-light fw-bold' : ''
                }`}
                onClick={() => onSelectTime(item)}
              >
                {item}
              </li>
            ))}
          </div>
        )}
      </ul>
    </OutsideClick>
  );
}

export default TimerPicker;

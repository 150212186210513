import React, { useState, useEffect, useContext } from 'react';
import { toast } from 'react-toastify';
import CardOrder from './CardOrder/CardOrder';
import './UserOrders.scss';
import {
  formatDateTime,
  getStoreLogo,
  maskCurrencyBRLWithoutSymbol,
} from '../../Utils/Index';
import { StoreContext, userDataContext } from '../MenuProvider/MenuProvider';
import SkeletonCardHistoryOrder from '../Skeletons/SkeletonCardHistoryOrder';
import OrderDetails from './OrderDetails/OrderDetails';
import { getHistoryOrders } from '../../services/Consumer';
import useInterval from '../../shared/UseInterval/UseInterval';

function UserOrders({ closeSideBar }) {
  const [orders, setOrders] = useState([]);

  const [noOrders, setNoOrders] = useState(true);
  const [viewOrderDetails, setViewOrderDetails] = useState(false);
  const [callHistorysOrders, setCallHistoryOrders] = useState(false);

  const [message, setMessage] = useState(null);
  const [headerTitle, setHeaderTitle] = useState('Meus pedidos');
  const [orderToViewDetails, setOrderToViewDetails] = useState(null);

  const { historyOrders, setHistoryOrders } = useContext(userDataContext);
  const { storeSettingsProvider } = useContext(StoreContext);

  useInterval(
    () => {
      callGetAllOrders();
    },
    callHistorysOrders ? 5000 : null
  );

  useEffect(() => {
    callGetAllOrders();
    window.scrollTo({ top: 0, behavior: 'smooth' });
    document.body.style.overflowY = 'hidden';
    return () => (document.body.style.overflowY = 'auto');
  }, []);

  useEffect(() => {
    if (Boolean(historyOrders.length)) {
      setOrders(historyOrders);
      setNoOrders(false);
    }
  }, []);

  async function callGetAllOrders() {
    const response = await getHistoryOrders();
    if (!response.success) {
      toast.error(
        'Não foi possível buscar seu histórico de pedidos, tente novamente ou entre em contato conosco.'
      );
      setMessage('Ocorreu um erro ao buscar seus pedidos :/');
      setNoOrders(true);
      setCallHistoryOrders(false);
      return;
    }

    formatOrderDetails(response.results);
  }

  function formatOrderDetails(orders) {
    let ordersTemp = orders;
    if (ordersTemp.length < 1) {
      setCallHistoryOrders(false);
      setNoOrders(true);
      setMessage('Faça um pedido e o mesmo será exibido aqui.');
      return;
    }

    ordersTemp = ordersTemp.map((order) => {
      order.image = getStoreLogo(
        storeSettingsProvider.settings.store.firebase_path
      );
      order.price = maskCurrencyBRLWithoutSymbol(order.preco_final);
      if (order.dh_concluido) order.date = formatDateTime(order.dh_concluido);
      else order.date = formatDateTime(order.dh_criacao);
      return order;
    });

    setHistoryOrders(ordersTemp);
    setOrders(ordersTemp);
    setNoOrders(false);
    setMessage(null);
    setCallHistoryOrders(true);
  }

  function handlerModalAction() {
    if (!viewOrderDetails) {
      closeSideBar(false);
      return;
    }
    setViewOrderDetails(false);
    setHeaderTitle('Meus pedidos');
  }

  function getOrderDetails(order) {
    setHeaderTitle(`PEDIDO #${order.codigo}`);
    setOrderToViewDetails(order);
    setViewOrderDetails(true);
  }

  return (
    <div className="user-orders">
      <div className="px-2 hstack w-100 justify-content-between align-items-center py-3">
        <h1 className="fw-bold text-primary fs-4 m-0">{headerTitle}</h1>
        <button
          type="button"
          className={`${
            viewOrderDetails
              ? 'btn btn-primary-fill p-0 border-none hstack outline-none bg-transparent'
              : 'btn-close'
          } `}
          aria-label={viewOrderDetails ? '' : 'Close'}
          onClick={() => handlerModalAction()}
        >
          {viewOrderDetails && (
            <span className="material-symbols-outlined">chevron_left</span>
          )}
        </button>
      </div>

      {noOrders ? (
        <div className="user-orders-container">
          {message ? <span>{message}</span> : <SkeletonCardHistoryOrder />}{' '}
        </div>
      ) : (
        <div className="user-orders-container">
          {viewOrderDetails ? (
            <OrderDetails
              infsOrder={orderToViewDetails}
              backToHistory={() => setViewOrderDetails(false)}
              updateInfsOrder={callGetAllOrders}
            />
          ) : (
            orders.map((order, index) => {
              return (
                <div
                  key={index}
                  onClick={() => getOrderDetails(order)}
                  style={{ width: '100%' }}
                >
                  <CardOrder
                    orderInfs={order}
                    customColor={storeSettingsProvider.settings.store.cor}
                  />
                </div>
              );
            })
          )}
        </div>
      )}
    </div>
  );
}

export default UserOrders;

import React, { useReducer } from 'react';
import SignInClient from './SignInClient';
import CreateClientAccount from './CreateClientAccount';
import SpringModal from '../../../shared/Modal/Modal';

function AuthenticateClient({ back, authenticated }) {
  const [options, updateOptions] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      signIn: true,
      phone: '',
    }
  );

  return (
    <SpringModal handleOpen={true} handleClose={() => back()}>
      {options.signIn ? (
        <SignInClient
          backStep={() => back()}
          onAuthenticate={() => authenticated()}
          createAccount={(phone) => updateOptions({ phone, signIn: false })}
        />
      ) : (
        <CreateClientAccount
          phone={options.phone}
          backToSignIn={() => updateOptions({ signIn: true })}
          onCreateAccount={() => authenticated()}
        />
      )}
    </SpringModal>
  );
}

export default AuthenticateClient;

import react, { useState } from 'react';

export const OrderContext = react.createContext({});

export default function OrderProvider(props) {
  const channel = new BroadcastChannel('delivery-legal-store');

  const [alertOnChangeStatus, setAlertOnChangeStatus] = useState({});
  const [autoPrintOrderReceipt, setAutoPrintOrderReceipt] = useState(false);
  const [enableAudioAlert, setEnableAudioAlert] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);
  const [storeName, setStoreName] = useState(null);
  const [serviceHours, setServiceHours] = useState({
    timeToOpen: null,
    timeToClose: null,
    currentDay: '',
    openToday: false,
    openedNow: false,
  });

  return (
    <OrderContext.Provider
      value={{
        alertOnChangeStatus,
        setAlertOnChangeStatus,
        autoPrintOrderReceipt,
        setAutoPrintOrderReceipt,
        enableAudioAlert,
        setEnableAudioAlert,
        totalOrders,
        setTotalOrders,
        storeName,
        setStoreName,
        serviceHours,
        setServiceHours,
        channel,
      }}
    >
      {props.children}
    </OrderContext.Provider>
  );
}

import React, { useState } from 'react';

function SearchOrder({ onSearchOrder }) {
  const [text, setText] = useState('');

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      normalizeTextToSearch();
    }

    if (e.key === 'Escape') {
      e.preventDefault();
      clearSearch();
    }
  };

  function normalizeTextToSearch() {
    onSearchOrder(
      text
        .toLocaleLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    );
  }

  function clearSearch() {
    setText('');
    onSearchOrder('');
  }

  return (
    <div className="w-100 hstack gap-1 align-items-center justify-content-between py-3">
      <div className="w-100 hstack align-items-center position-relative">
        <input
          type="text"
          placeholder="Codigo ou nome do cliente"
          className="form-control py-2"
          value={text}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={handleKeyDown}
        />

        <button
          type="button"
          className="bg-dark bg-opacity-25 btn-close fs-8 mx-2 p-2 position-absolute right rounded-4"
          aria-label="Close"
          onClick={() => clearSearch()}
          disabled={!text}
        ></button>
      </div>

      <button
        className="btn btn-primary"
        onClick={() => normalizeTextToSearch()}
        disabled={!text}
      >
        Pesquisar
      </button>
    </div>
  );
}

export default SearchOrder;

import React from 'react';
import './Footer.scss';
import { IconHeart } from '../../assets/Icons';
import { SM_LOGO_IMAGE_URL } from '../../Utils/Index';

export default function Footer() {
  return (
    <div className="footer">
      <div className="footer-container">
        <div className="footer__content">
          <img
            src={SM_LOGO_IMAGE_URL}
            className="footer__content-image"
            alt="Icone do Delivery.legal"
          />
          <div className="footer__content__topics">
            <div>
              <h3 className="footer__content__topics-title">Site</h3>
              <ul className="footer__content__topics-list">
                <li className="footer__content__topics-item">
                  <a href="/home#plans">planos</a>
                </li>
                <li className="footer__content__topics-item">
                  <a href="/home#howWork">como funciona</a>
                </li>
                <li className="footer__content__topics-item">
                  <a href="/home#about">quem somos</a>
                </li>
                <li className="footer__content__topics-item">
                  <a href="/home#contacts">contato</a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="footer__content__topics-title">Comercial</h3>
              <ul className="footer__content__topics-list">
                <li className="footer__content__topics-item">
                  <a target="_blank" href="/login">
                    Acessar conta
                  </a>
                </li>
                <li className="footer__content__topics-item">
                  <a target="_blank" href="/login">
                    Criar conta
                  </a>
                </li>
                <li className="footer__content__topics-item">
                  <a target="_blank" href="/recuperar-senha">
                    Recuperar senha
                  </a>
                </li>
              </ul>
            </div>
            <div>
              <h3 className="footer__content__topics-title">Suporte</h3>
              <ul className="footer__content__topics-list">
                <li className="footer__content__topics-item">
                  <a target="_blank" href="/suporte">
                    Central de Ajuda
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <span className="footer-message">
          Feito com&nbsp;
          <IconHeart />
          &nbsp;em São Paulo
        </span>
        <span className="fs-8 text-center text-secondary text-white w-100">
          © 2025 Delivery.legal - Todos os direitos reservados
        </span>
      </div>
    </div>
  );
}

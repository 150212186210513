import React from 'react';

function OrderProducts({ products }) {
  return (
    <div className="p-2 bg-light h-auto rounded mb-2 border shadow-sm ">
      <h5 className="fw-bold mb-2 text-primary">Itens do pedido:</h5>
      {products.map((order) => {
        return (
          <div key={order.cod_pedido_items.toString()} className="hstack">
            <div className="w-100 hstack align-items-start justify-content-between position-relative overflow-hidden mb-2 ">
              <div className="justify-content-between vstack w-100">
                <div className="w-100 hstack justify-content-between position-relative">
                  <span className="fs-7 text-black fw-bold letter-spacing bg-light z-1">
                    {order.qtd}x {order.nome}
                  </span>
                  <span className="pointer">
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                    . . . . . . . . . . . .
                  </span>
                  <div className="z-1 hstack gap-1 bg-light">
                    <span className="fs-7 text-body-secondary fw-bold letter-spacing ">
                      {order.preco}
                    </span>
                    <span className="fs-7 text-black fw-bold letter-spacing">
                      ({order.subtotal})
                    </span>
                  </div>
                </div>
                <div className="d-block ml-2 pl-2">
                  {order.complementos.map((complement, index) => {
                    return (
                      <div
                        key={`${order.cod_pedido_items}_${index}`}
                        className="d-flex justify-content-between"
                      >
                        <span
                          className="fs-7 text-black fw-bold letter-spacing z-1 bg-light"
                          style={{ paddingLeft: 16 }}
                        >
                          {complement.grupo}: {complement.nome}
                        </span>
                        <span className="pointer break-line">
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . . . . . . . . . . .
                          . . . . . . . . . . . . . . . . . .
                        </span>
                        <span className="bg-light fs-8 fw-bold letter-spacing text-black z-1">
                          {complement.preco}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default OrderProducts;

import React from 'react';

function OrderDeliveryType({ isDelivery, deliveryPrice }) {
  return (
    <button className="order-tooltip border btn fs-7 h-100 p-0 position-relative px-2 rounded-3 text-success bg-white border-success">
      <span className="hstack material-symbols-outlined">
        {isDelivery ? 'motorcycle' : 'concierge'}
      </span>

      {isDelivery && (
        <div className="text-dark order-tooltip-content position-absolute tooltip-content bg-white mt-2 hstack gap-1 p-2 rounded">
          <span className="fs-7 text-truncate">Valor entrega: </span>
          <span className="fs-7 fw-bold">{deliveryPrice}</span>
        </div>
      )}
    </button>
  );
}

export default OrderDeliveryType;

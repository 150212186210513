import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
  },
  content: {
    backgroundColor: 'var(--color-white)',
    padding: 0,
    width: '100%',
    height: '100%',
    position: 'relative',
    outline: 'none',
    borderRadius: 8,
    overflow: 'auto',
  },
}));

export default function ProductModal({ handleOpen, children, handleClose }) {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(handleOpen);
    return () => setOpen(false);
  }, [handleOpen]);

  function closeModal() {
    setOpen(false);
    handleClose(false);
  }

  return (
    <Modal
      aria-labelledby="product-modal-title"
      aria-describedby="product-modal-description"
      className={styles.container}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={styles.content}>{children}</div>
    </Modal>
  );
}

import React, { useEffect, useState } from 'react';
import { formatDateTime } from '../../../../Utils/Index';

function OrderStatusHistory({ order }) {
  const [history, setHistory] = useState([]);

  useEffect(() => {
    handleOrderLogs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleOrderLogs() {
    const items = order.historico.map((status) => {
      if (status.time_formated) return status;
      status.time_formated = formatDateTime(status.datahora);
      return status;
    });
    setHistory(items);
  }

  return (
    <button className="order-tooltip btn btn-primary-fill max-w-fit p-0 position-relative w-100">
      <div className="hstack w-100 gap-1">
        <span className="fs-5 material-symbols-outlined">schedule</span>
        <span className="fs-7">
          {order.last_status.status} {order.last_status.time}
        </span>
      </div>

      <div className="order-tooltip-content">
        <span className="text-left pb-1 fs-6 fw-bold">
          Histórico do pedido:
        </span>
        {history.map((item, index) => {
          return (
            <div key={index} className="fs-7 mb-1 text-truncate">
              <span>
                {item.status} -{' '}
                <span className="fw-bold">{item.time_formated}</span>
              </span>
            </div>
          );
        })}
      </div>
    </button>
  );
}

export default OrderStatusHistory;

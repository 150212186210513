import { httpService } from './HttpService';

export const getAllProducts = async () => {
  const data = await httpService('painel/gestor', 'getAllProducts', {});
  return data;
};

export const updateProduct = async (productData) => {
  const data = await httpService('painel/gestor', 'updateProduct', {
    ...productData,
  });
  return data;
};

export const uploadPhotoProduct = async (tipo, cod_produto, local, token) => {
  const data = await httpService('painel/gestor', 'uploadPhoto', {
    tipo,
    cod_produto,
    local,
    token,
  });
  return data;
};

export const uploadPhotoLogo = async (tipo, local, token) => {
  const data = await httpService('painel/gestor', 'uploadPhoto', {
    tipo,
    local,
    token,
  });
  return data;
};

export const getAllGroupComplements = async () => {
  const data = await httpService('painel/gestor', 'getAllGrupos');
  return data;
};

export const getItemsByIdGroup = async (codigo) => {
  const data = await httpService('painel/gestor', 'getItemsByIdGroup', {
    codigo,
  });
  return data;
};

export const updateComplementoItem = async (
  codigo_item_detalhe,
  cod_opcao,
  nome,
  preco,
  ativo,
  descricao
) => {
  const data = await httpService('painel/gestor', 'updateComplementoItem', {
    codigo_item_detalhe,
    cod_opcao,
    nome,
    preco,
    ativo,
    descricao,
  });
  return data;
};

export const updateComplementoGrupo = async (
  codigo,
  nome,
  ativo,
  qtd_minima,
  qtd_maxima,
  items_opcoes
) => {
  const data = await httpService('painel/gestor', 'updateComplementoGrupo', {
    codigo,
    nome,
    ativo,
    qtd_minima,
    qtd_maxima,
    items_opcoes,
  });
  return data;
};

export const includeGroupOnProduct = async (
  cod_produto,
  cod_produto_opcoes
) => {
  const data = await httpService('painel/gestor', 'includeGroupOnProduct', {
    cod_produto,
    cod_produto_opcoes,
  });
  return data;
};

export const getGroupsByProduct = async (cod_produto) => {
  const data = await httpService('painel/gestor', 'getGroupsByProduct', {
    cod_produto,
  });
  return data;
};

export const excludeGroupOnProduct = async (
  cod_produto,
  cod_produto_opcoes
) => {
  const data = await httpService('painel/gestor', 'excludeGroupOnProduct', {
    cod_produto,
    cod_produto_opcoes,
  });
  return data;
};

export const updateCategory = async (codigo, nome, ativo) => {
  const data = await httpService('painel/gestor', 'updateCategory', {
    codigo,
    nome,
    ativo,
  });
  return data;
};

export const deleteProduct = async (codigo) => {
  const data = await httpService('painel/gestor', 'deleteProduct', { codigo });
  return data;
};

export const deleteCategory = async (codigo) => {
  const data = await httpService('painel/gestor', 'deleteCategory', { codigo });
  return data;
};

export const changeColor = async (cor_primaria) => {
  const response = await httpService('painel/gestor', 'changeColor', {
    cor_primaria,
  });
  if (!response || !response.success) throw response;
  return true;
};

import React, { useEffect, useRef } from 'react';

function OutsideClick({ children, listenOutsideEvent, onClickOutside, onRef }) {
  const ref = useRef();

  useEffect(() => {
    if (listenOutsideEvent)
      document.addEventListener('mousedown', handleClickOutside);
    else document.removeEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listenOutsideEvent]);

  useEffect(() => {
    onRef && onRef(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  function handleClickOutside(event) {
    if (ref.current && !ref.current.contains(event.target)) {
      onClickOutside();
    }
  }

  return React.cloneElement(children, { ref });
}

export default OutsideClick;

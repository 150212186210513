import React, { useEffect, useReducer } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { MenuItem, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import {
  updateTimeDelivery,
  getTimeDelivery,
} from '../../../services/Configuration';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default function TempoEstimado({
  firstAccess = false,
  handleConfigureStore,
}) {
  const deliveryTimes = generateDeliveryTimes();

  const initialState = {
    delivery: 35,
    retirada: 35,
  };

  function generateDeliveryTimes() {
    const times = [];
    for (let index = 5; index < 60; index += 5) {
      times.push({ value: index, label: `${index} min` });
    }
    times.push({ value: 60, label: '1 hora' });
    times.push({ value: 90, label: '1:30' });
    return times;
  }

  const [days, updateDays] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      segunda: initialState,
      terca: initialState,
      quarta: initialState,
      quinta: initialState,
      sexta: initialState,
      sabado: initialState,
      domingo: initialState,
      feriado: initialState,
    }
  );

  useEffect(() => {
    getStoreDeliveryTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function convertToTime(minutes) {
    const hours = `${Math.floor(minutes / 60)}`;
    const mins = `${minutes % 60}`;
    return `${hours.padStart(2, '0')}:${mins.padStart(2, '0')}:00`;
  }

  function saveStoreDeliveryTime() {
    const payload = Object.keys(days).reduce((acc, day) => {
      acc[day] = {
        delivery: convertToTime(days[day].delivery),
        retirada: convertToTime(days[day].retirada),
      };
      return acc;
    }, {});

    updateTimeDelivery({ obj: payload })
      .then(() => {
        toast.success('Tempo estimado atualizado com sucesso.');
        if (firstAccess) handleConfigureStore(true);
      })
      .catch((error) => {
        console.error('UPDATE_STORE_DELIVERY_TIME', error);
        toast.error(
          'Erro ao atualizar tempo estimado, tente novamente ou entre em contato conosco.'
        );
      });
  }

  function getStoreDeliveryTime() {
    getTimeDelivery()
      .then((time) => {
        updateStoreDeliveryTime(time);
      })
      .catch((error) => {
        console.error('GET_STORE_DELIVERY_TIME', error);
        toast.error(
          'Erro ao buscar tempo estimado, tente novamente ou entre em contato conosco.'
        );
      });
  }

  function updateStoreDeliveryTime(time) {
    const daysOfWeek = [
      'segunda',
      'terca',
      'quarta',
      'quinta',
      'sexta',
      'sabado',
      'feriado',
      'domingo',
    ];

    const updatedDays = daysOfWeek.reduce((acc, day) => {
      acc[day] = {
        delivery: convertTimeFormat(time[`${day}_delivery`]),
        retirada: convertTimeFormat(time[`${day}_retirada`]),
      };
      return acc;
    }, {});

    updateDays(updatedDays);
  }

  function convertTimeFormat(timeString) {
    const [hours, minutes] = timeString.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;
    const roundedMinutes = Math.round(totalMinutes / 5) * 5;
    return Math.max(5, Math.min(roundedMinutes, 90));
  }

  const handleTimeChange = (day, type, value) => {
    updateDays({ [day]: { ...days[day], [type]: value } });
  };

  const renderRow = (day, label) => (
    <StyledTableRow key={day}>
      <StyledTableCell component="th" scope="row">
        {label}
      </StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          select
          label="Tempo"
          value={days[day].delivery}
          variant="filled"
          size="small"
          style={{ width: 100 }}
        >
          {deliveryTimes.map((time) => (
            <MenuItem
              key={time.value}
              value={time.value}
              onClick={() => handleTimeChange(day, 'delivery', time.value)}
            >
              {time.label}
            </MenuItem>
          ))}
        </TextField>
      </StyledTableCell>
      <StyledTableCell align="center">
        <TextField
          select
          label="Tempo"
          value={days[day].retirada}
          variant="filled"
          size="small"
          style={{ width: 100 }}
        >
          {deliveryTimes.map((time) => (
            <MenuItem
              key={time.value}
              value={time.value}
              onClick={() => handleTimeChange(day, 'retirada', time.value)}
            >
              {time.label}
            </MenuItem>
          ))}
        </TextField>
      </StyledTableCell>
    </StyledTableRow>
  );

  return (
    <div className="conf-store">
      <div className="vstack pb-2">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Tempo estimado</h3>
        <span className="fs-7 text-body-secondary">
          Configure o tempo estimado para entrega/retirada por dias da semana e
          feriados.
        </span>
      </div>

      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Dia</StyledTableCell>
              <StyledTableCell align="center">Entrega</StyledTableCell>
              <StyledTableCell align="center">Retirada</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {renderRow('segunda', 'Segunda-feira')}
            {renderRow('terca', 'Terça-feira')}
            {renderRow('quarta', 'Quarta-feira')}
            {renderRow('quinta', 'Quinta-feira')}
            {renderRow('sexta', 'Sexta-feira')}
            {renderRow('sabado', 'Sábado')}
            {renderRow('domingo', 'Domingo')}
            {renderRow('feriado', 'Feriado')}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        className="Btn add-items save-updates"
        onClick={() => saveStoreDeliveryTime()}
      >
        Salvar alterações
      </div>
    </div>
  );
}

import React from 'react';
import ButtonWithCount from '../../../shared/ButtonWithCount/ButtonWithCount';

function OrderMonitorEmptyState(props) {
  if (!props.storeIsOpen) {
    return (
      <div className="alert alert-warning mt-3" role="alert">
        <h4 className="alert-heading">Loja fechada.</h4>
        <p>Os pedidos aparecerão aqui quando a loja estiver aberta.</p>

        <ButtonWithCount
          label="Verificar status da loja"
          sessionKey="lastStoreStatusCheck"
          delay={30}
          onClick={() => props.checkStoreStatus()}
        />
      </div>
    );
  }

  if (props.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <div className="spinner-border text-primary" role="status"></div>
      </div>
    );
  }

  if (props.errorOnLoad) {
    return (
      <div className="alert alert-danger mt-3" role="alert">
        <h4 className="alert-heading">Erro!</h4>
        <p>Ocorreu um erro ao carregar os pedidos, tente novamente.</p>

        <ButtonWithCount
          label="Tentar novamente"
          sessionKey="retryLoadOrders"
          delay={30}
          onClick={() => props.retryLoadOrders()}
        />
      </div>
    );
  }

  if (props.isEmpty) {
    return (
      <div className="vstack w-100 text-center mt-3">
        <h4 className="fw-bold text-dark m-0">
          Nenhum pedido em produção no momento.
        </h4>
        <span>
          Os pedidos aparecerão aqui quando o gestor de pedidos aceitar e mover
          para produção.
        </span>
      </div>
    );
  }

  return props.children;
}

export default OrderMonitorEmptyState;

import React from 'react';

function KitchenOrderCard({ order, onCompleted }) {
  return (
    <div className="col-12 col-md-6 col-lg-4 mb-2">
      <div className="card h-100 justify-content-between">
        <div className="card-header p-2 d-flex justify-content-between align-items-center">
          <h5 className="m-0">
            Pedido <span className="fw-bold">#{order.codigo}</span>
          </h5>
          <span className="text-muted">
            Horário:{' '}
            {new Date(order.dh_inicio).toLocaleTimeString('pt-BR').slice(0, 5)}
          </span>
        </div>

        <div className="card-body p-2 h-100 justify-content-between vstack">
          <div>
            {order.items.map((item) => (
              <div key={item.cod_pedido_items} className="mb-2">
                <div className="d-flex justify-content-start align-items-center gap-1">
                  <span className="fw-bold fs-7">{item.qtd}x</span>
                  <span>{item.produto_nome}</span>
                </div>

                {item.complementos.length > 0 && (
                  <ul className="ps-4 m-0">
                    {item.complementos.map((complement, idx) => (
                      <li
                        key={`${complement.cod_pedido_items}-${idx}`}
                        className="d-flex justify-content-between"
                      >
                        <small>{complement.complemento_nome}</small>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))}

            {Boolean(order.obs) && (
              <div className="vstack border-top py-1">
                <span className="fs-7 text-secondary">Observação:</span>
                <span className="fs-7">{order.obs}</span>
              </div>
            )}
          </div>

          <div className="border-top pt-1">
            <button
              onClick={() => onCompleted(order.codigo)}
              className="btn btn-outline-success fw-bold w-100"
            >
              Liberar pedido
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default KitchenOrderCard;

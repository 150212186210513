import React, { useReducer } from 'react';
import TimerPicker from '../TimerPicker/TimerPicker';

function WorkTime({ value, onUpdateTime }) {
  const [time, updateTime] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      hours: value.split(':')[0],
      minutes: value.split(':')[1],
      hoursAvailable: [...Array(24).keys()].map((h) =>
        h.toString().padStart(2, '0')
      ),
      minutesAvailable: [...Array(12).keys()].map((m) =>
        (m * 5).toString().padStart(2, '0')
      ),
    }
  );

  function onChangeTime(field, params) {
    let newTime = '';
    if (field === 'hours') newTime = `${params}:${time.minutes}:00`;
    else newTime = `${time.hours}:${params}:00`;
    onUpdateTime(newTime);
    updateTime({ [field]: time });
  }

  return (
    <div className="hstack justify-content-center">
      <div className="hstack" style={{ width: 60 }}>
        <TimerPicker
          css="text-end"
          options={time.hoursAvailable}
          selected={time.hours}
          onChange={(value) => onChangeTime('hours', value)}
        />
        <span>:</span>
        <TimerPicker
          css="text-start"
          options={time.minutesAvailable}
          selected={time.minutes}
          onChange={(value) => onChangeTime('minutes', value)}
        />
      </div>
    </div>
  );
}

export default WorkTime;

import React, { useEffect, useReducer } from 'react';
import { updateHourStore, getHourStore } from '../../../services/Configuration';
import { toast } from 'react-toastify';
import WorkTime from '../../../shared/WorkTime/WorkTime';
import SwitchButton from '../../../shared/SwitchButton/SwitchButton';

const initialState = structuredClone({
  inicio: '10:00:00',
  fim: '19:00:00',
  aberto: true,
});

const daysOfWeek = [
  { label: 'Segunda-feira', id: 'segunda' },
  { label: 'Terça-feira', id: 'terca' },
  { label: 'Quarta-feira', id: 'quarta' },
  { label: 'Quinta-feira', id: 'quinta' },
  { label: 'Sexta-feira', id: 'sexta' },
  { label: 'Sábado', id: 'sabado' },
  { label: 'Domingo', id: 'domingo' },
];

export default function HorarioLoja({
  firstAccess = false,
  handleConfigureStore,
}) {
  const [workTime, updateWorkTime] = useReducer(
    (state, action) => {
      const { id, payload, value } = action;
      if (payload === 'update_times') return value;
      return { ...state, [id]: { ...state[id], [payload]: value } };
    },
    {
      segunda: initialState,
      terca: initialState,
      quarta: initialState,
      quinta: initialState,
      sexta: initialState,
      sabado: initialState,
      domingo: initialState,
    }
  );

  useEffect(() => {
    getStoreWorkTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function getStoreWorkTime() {
    getHourStore()
      .then((result) => {
        const values = {};
        for (const key in workTime) {
          values[key] = {
            inicio: result[`${key}_inicio`],
            fim: result[`${key}_fim`],
            aberto: result[`${key}_aberto`] === 1,
          };
        }
        updateWorkTime({ payload: 'update_times', value: values });
      })
      .catch((err) => {
        console.error('ERROR_ON_GET_STORE_WORK_TIME', err);
      });
  }

  function saveStoreWorkTime() {
    updateHourStore({ obj: workTime })
      .then(() => {
        toast.success('Horários atualizados com sucesso');
        if (firstAccess) handleConfigureStore(true);
      })
      .catch((error) => {
        console.error('SAVE_STORE_TIME_ERROR', error);
        toast.error(
          'Ocorreu um erro ao salvar os horários de funcionamento, tente novamente.'
        );
      });
  }

  return (
    <div className="conf-store">
      <div className="vstack pb-2">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Horários</h3>
        <span className="fs-7 text-body-secondary">
          Configure o horário de atendimento do seu estabelecimento.
        </span>
      </div>

      <div className="container mt-2">
        <table className="table table-bordered text-center align-middle">
          <thead className="table-light">
            <tr>
              <th>Dia</th>
              <th>Abre</th>
              <th>Fecha</th>
              <th>Aberto?</th>
            </tr>
          </thead>
          <tbody>
            {daysOfWeek.map(({ id, label }) => (
              <tr key={id}>
                <td>{label}</td>
                <td align="center">
                  {workTime[id].aberto ? (
                    <WorkTime
                      value={workTime[id].inicio}
                      onUpdateTime={(time) =>
                        updateWorkTime({ id, value: time, payload: 'inicio' })
                      }
                    />
                  ) : (
                    <span className="fs-7 text-danger">Fechado</span>
                  )}
                </td>
                <td align="center">
                  {workTime[id].aberto ? (
                    <WorkTime
                      value={workTime[id].fim}
                      onUpdateTime={(time) => {
                        updateWorkTime({ id, value: time, payload: 'fim' });
                      }}
                    />
                  ) : (
                    <span className="fs-7 text-danger">Fechado</span>
                  )}
                </td>
                <td>
                  <SwitchButton
                    initialValue={workTime[id].aberto}
                    onChange={() =>
                      updateWorkTime({
                        id,
                        payload: 'aberto',
                        value: !workTime[id].aberto,
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <button
        className="btn btn-primary fw-bold max-w-fit my-3 w-100"
        onClick={() => saveStoreWorkTime()}
      >
        Salvar alterações
      </button>
    </div>
  );
}
